export const checkTime = () => {
  var d = new Date(); // current time
  var hours = d.getHours();
  var mins = d.getMinutes();
  // var day = d.getDay();

  return (
    hours >= (+process.env.REACT_APP_SHOP_START_TIME || 9) &&
    (hours < (+process.env.REACT_APP_SHOP_END_TIME || 21) ||
      (hours === (+process.env.REACT_APP_SHOP_END_TIME || 21) && mins <= 0))
  );
};
