import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Api } from "../service/Api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "./Loader";

const passwordInitialState = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const ChangePasswordModal = ({ show, handleClose, mobileNumber }) => {
  const [error, setError] = useState("");
  const [password, setPassword] = useState(passwordInitialState);
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  const updatePasswordHandler = () => {
    if (
      password?.oldPassword?.trim()?.length <= 0 ||
      password?.newPassword?.trim()?.length <= 0 ||
      password?.confirmPassword?.trim()?.length <= 0
    ) {
      setError("Password cannot be empty");
      return;
    }
    if (password?.newPassword !== password?.confirmPassword) {
      setError("New and old password must be same");
      return;
    }
    setError("");

    try {
      setLoading(true);
      //   Api.checkNumberExist(mobileNumber).then((res) => {
      //     if (res.data?.data) {
      // that means number is not exist in customer table
      const data = {
        phone_number: mobileNumber,
        oldPassword: password?.oldPassword,
        newPassword: password?.newPassword,
      };
      Api.updatePassword(data).then((res) => {
        if (res.data.meta.code === 301) {
          history("/" + res.data.meta.url);
        }
        if (res.data.meta.code === 200) {
          setError("");
          toast("Password changed successfully!");
          handleClose();
        } else {
          setError("Please enter valid old password or number!");
        }
        setLoading(false);
      });
      // } else {
      //   // if number is exist in customer tbale
      //   setLoading(false);
      //   setError("Mobile number is not valid!");
      // }
      //   });
    } catch (err) {
      setError("Something went wrong!");
      setLoading(false);
      console.error(err);
    }
  };
  const passwordChangeHandler = (e) => {
    setPassword((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Modal.Body style={{ textAlign: "left" }}>
            <Form>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalName"
              >
                <Form.Label column sm={2}>
                  Old Password
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="password"
                    placeholder="Enter old password"
                    name="oldPassword"
                    onChange={(e) => passwordChangeHandler(e)}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalName"
              >
                <Form.Label column sm={2}>
                  New Password
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="password"
                    placeholder="Enter new password"
                    name="newPassword"
                    onChange={(e) => passwordChangeHandler(e)}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formHorizontalName"
              >
                <Form.Label column sm={2}>
                  Confirm Password
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="password"
                    placeholder="Enter confirm password"
                    name="confirmPassword"
                    onChange={(e) => passwordChangeHandler(e)}
                  />
                </Col>
              </Form.Group>
              {error && <p className="text-danger mt-2">{error}</p>}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={updatePasswordHandler}>
              Update
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default ChangePasswordModal;
