import React from "react";
import { RestaurantState } from "../../../context/Context";
import { checkTime } from "../../../utils/helper";

const MenuItems = ({ item, setIsShopModalOpen }) => {
  const selected = "border-2 border-blue-500";
  const { state, dispatch } = RestaurantState();
  var foundInCart = false;
  state.map((q, i) => {
    if (q.item_name === item.item_name && q.quantity > 0) {
      foundInCart = true;
    }
  });
  var qty = 0;
  state.forEach((a, i) => {
    if (a.id === item.id) {
      qty = a.quantity;
    }
  });
  return (
    <>
      <div className="pr-2 pb-2">
        <div className="w-[120px] max-h-[145px]">
          <img
            className={`w-[110px] h-[70px] rounded-lg`}
            src={item.image}
            alt=""
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "no-image.png";
            }}
          />
          <div className="text-center py-0 h-12 text-[12px] font-semibold	">
            {item.item_name + " (" + item.kitchen.brand_name + ")"}
          </div>
          <div className="itemDetails-button d-flex flex-column mt-auto text-white">
            {!foundInCart && (
              <div className="itemDetails-button-container">
                <div
                  onClick={() => {
                    if (checkTime()) {
                      dispatch({
                        type: "INCREASE_QTY",
                        payload: item,
                      });
                    } else {
                      setIsShopModalOpen(true);
                    }
                  }}
                  className="itemDetails-add-btn"
                >
                  Add
                </div>
              </div>
            )}
            {foundInCart && (
              <>
                <div className="itemDetails-qty-controls">
                  <div
                    className="itemDetails-qty-button"
                    onClick={() => {
                      if (checkTime()) {
                        dispatch({
                          type: "DECREASE_QTY",
                          payload: item,
                        });
                      } else {
                        setIsShopModalOpen(true);
                      }
                    }}
                  >
                    -
                  </div>
                  <div className="itemDetails-qty-display"> {qty}</div>
                  <div
                    className="itemDetails-qty-button"
                    onClick={() => {
                      if (checkTime()) {
                        dispatch({
                          type: "INCREASE_QTY",
                          payload: item,
                        });
                      } else {
                        setIsShopModalOpen(true);
                      }
                    }}
                  >
                    +
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuItems;
