// ForgotPasswordModal.js
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Api } from "../service/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";

const ForgotPasswordModal = ({ show, onClose, onVerified }) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const history = useNavigate();

  const modalDefaultState = () => {
    setMobileNumber("");
    setError("");
    setLoading(false);
    setIsCodeSent(false);
    setIsVerifying(false);
    setPassword("");
    onClose();
  };

  // Send verification code
  const handleSendCode = async () => {
    try {
      setLoading(true);
      Api.checkNumberExist(mobileNumber).then((res) => {
        if (res.data?.data) {
          // that means number is exist in customer table
          const data = {
            phone_number: mobileNumber,
          };
          Api.sendOTP(data).then((res) => {
            if (res.data.meta.code === 301) {
              history("/" + res.data.meta.url);
            }
            if (res.data.meta.code === 200) {
              setIsCodeSent(true);
              setError("");
              toast("OTP sent successfully!");
            } else {
              setError("Error sending OTP.");
            }
            setLoading(false);
          });
        } else {
          // if number is not exist in customer tbale
          setLoading(false);
        }
      });
    } catch (err) {
      setError("Error sending verification code.");
      setLoading(false);
      console.error(err);
    }
  };

  // Verify the code
  const handleVerifyCode = async () => {
    try {
      setLoading(true);
      setIsVerifying(true);
      const OTPData = {
        phone_number: mobileNumber,
        code: verificationCode,
        password: password,
      };
      Api.verifyOTP(OTPData).then((res) => {
        if (res.data.meta.code === 301) {
          history("/" + res.data.meta.url);
        }
        if (res.data.meta.code === 200) {
          modalDefaultState();
          toast("Password updated successfully!");
        } else {
          setError("Invalid OTP. Please try again.");
        }
        setLoading(false);
      });
    } catch (err) {
      setError("Error verifying the code.");
      setLoading(false);
      console.error(err);
    } finally {
      setIsVerifying(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>Forgot Password</h2>
          <input
            type="tel"
            className="modal-input"
            placeholder="e.g., +91234567890"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            disabled={isCodeSent}
          />
          {isCodeSent && (
            <>
              <input
                type="text"
                className="modal-input"
                placeholder="Enter OTP"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required={true}
              />
              <input
                type="password"
                className="modal-input"
                placeholder="Enter New Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required={true}
              />
            </>
          )}
          {error && <p className="text-danger mt-2">{error}</p>}
          {loading ? (
            <Loader />
          ) : !isCodeSent ? (
            <button
              className="btn-confirm"
              onClick={handleSendCode}
              disabled={mobileNumber.length < 10}
            >
              Verify
            </button>
          ) : (
            <button
              className="btn-confirm"
              onClick={handleVerifyCode}
              disabled={isVerifying || verificationCode.length === 0}
            >
              {isVerifying ? "Verifying..." : "Verify Code"}
            </button>
          )}
          <button className="btn-mclose" onClick={() => modalDefaultState()}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
