import React, { useEffect, useState } from "react";
import "./styles.css";
import { RestaurantState } from "../context/Context";
import { Link, useNavigate } from "react-router-dom";
import { RiFileList3Line } from "react-icons/ri";
import { FaShoppingCart } from "react-icons/fa";
import MobileVerificationModal from "./MobileVerificationModal";
import { Api } from "../service/Api";
import { CgProfile } from "react-icons/cg";
import { Col, Modal, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import ForgotPasswordModal from "./ForgotPasswordModal";
import ChangePasswordModal from "./ChangePasswordModal";
// import Modal from 'react-bootstrap/Modal';

const customerInitialValue = {
  name: "",
  address: "",
  number: "",
};

const Header = () => {
  const { state } = RestaurantState();
  const totalQty = state.reduce((sum, item) => sum + (item?.quantity || 0), 0);
  const backURL = localStorage.getItem("slug")
    ? "/" + localStorage.getItem("slug")
    : "/";
  const mobile_no = localStorage.getItem("mobile_no");
  const [showModal, setShowModal] = useState(false);
  const pathname = window.location.pathname;
  const extractedSlug = localStorage.getItem("slug")
    ? localStorage.getItem("slug")
    : pathname.startsWith("/")
    ? pathname.split("/")[1]
    : pathname;
  const [restaurant, setRestaurant] = useState([]);
  const [show, setShow] = useState(false);
  const [customerDetail, setCustomerDetail] = useState(customerInitialValue);
  const [showForgotPasswordField, setShowForgotPasswordField] = useState(false);
  const [showChangePasswordField, setShowChangePasswordField] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const history = useNavigate();
  const handleLoginClick = () => {
    setShowModal(true);
  };

  // Function to handle successful verification and update the state
  const handleVerified = (mobile) => {
    localStorage.setItem("mobile_no", mobile);
    setShowModal(false);
  };
  useEffect(() => {
    Api.getRestaurant(extractedSlug).then((res) => {
      if (res.data.meta.code === 301) {
        history("/" + res.data.meta.url);
      }
      if (res.data.meta.code === 200) {
        setRestaurant(res.data.data);
        localStorage.setItem("restaurant_name", res.data.data.brand_name);
        localStorage.setItem("restaurant_id", res.data.data.id);
      }
    });
  }, [extractedSlug]);

  const getProfile = () => {
    const number = localStorage.getItem("mobile_no");
    Api.getProfileDetail(number).then((res) => {
      setCustomerDetail({
        name: res?.data?.data?.name,
        address: res?.data?.data?.address,
        number: res?.data?.data?.mobile_number,
      });
    });
  };

  const updateProfileHandler = () => {
    Api.updateCustomerProfile(customerDetail).then((res) => {
      if (res && res?.data?.data) {
        handleClose();
        toast(res?.data?.meta?.message);
      }
    });
  };

  return (
    <>
      <div
        className="d-flex items-center justify-between text-center sticky top-0 px-4 py-2"
        style={{ backgroundColor: "var(--primary-color)" }}
      >
        {/* Logo Section */}

        <div>
          <Link to={backURL}>
            <img
              className="rounded h-[50px] w-[60px] pt-1 pb-1 m-auto"
              src={restaurant.brand_image}
              alt={restaurant.brand_name}
            />
          </Link>
        </div>

        {/* Orders and Cart Section */}
        <div className="d-flex items-center gap-4">
          {/* Conditional Orders/Login Button */}
          {mobile_no ? (
            <>
              <button
                className="cursor-pointer text-white font-bold py-2 rounded inline-flex items-center"
                onClick={() => {
                  localStorage.removeItem("mobile_no");
                  history("/");
                }}
              >
                Logout
              </button>
              {/* <Link to="/pastOrders"> */}
              <button
                className="cursor-pointer text-white font-bold rounded inline-flex items-center"
                onClick={() => {
                  getProfile();
                  handleShow();
                }}
              >
                <CgProfile className="me-2" />
                <span>Profile</span>
              </button>
              {/* </Link> */}
              <Link to="/pastOrders">
                <button className="cursor-pointer text-white font-bold rounded inline-flex items-center">
                  <RiFileList3Line className="me-2" />
                  <span>Orders</span>
                </button>
              </Link>
            </>
          ) : (
            <button
              onClick={handleLoginClick}
              className="cursor-pointer text-white font-bold rounded inline-flex items-center"
            >
              <RiFileList3Line className="me-2" />
              <span>Login</span>
            </button>
          )}

          {/* Cart Icon */}
          <Link to="/cart" className="d-flex items-center text-white font-bold">
            <FaShoppingCart color="white" fontSize="20px" className="me-2" />
            <span>{totalQty}</span>
          </Link>
        </div>
      </div>
      {/* Mobile Verification Modal */}
      <MobileVerificationModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onVerified={handleVerified}
        showForgotField={() => setShowForgotPasswordField(true)}
      />
      <ForgotPasswordModal
        show={showForgotPasswordField}
        onClose={() => setShowForgotPasswordField(false)}
      />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "left" }}>
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalName"
            >
              <Form.Label column sm={2}>
                Name
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  value={customerDetail.name}
                  autoFocus
                  onChange={(e) =>
                    setCustomerDetail((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalAddress"
            >
              <Form.Label column sm={2}>
                Address
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder="Enter address"
                  value={customerDetail.address}
                  onChange={(e) =>
                    setCustomerDetail((prev) => ({
                      ...prev,
                      address: e.target.value,
                    }))
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalNumber"
            >
              <Form.Label column sm={2}>
                Number
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  placeholder="Enter number"
                  value={customerDetail.number}
                  disabled
                />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="warning"
            onClick={() => {
              handleClose();
              setShowChangePasswordField(true);
            }}
          >
            Change Password
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={updateProfileHandler}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <ChangePasswordModal
        show={showChangePasswordField}
        handleClose={() => setShowChangePasswordField(false)}
        mobileNumber={mobile_no}
      />
    </>
  );
};

export default Header;
