import React from "react";
import { checkTime } from "../../../utils/helper";

const MenuItemFilter = ({
  item,
  setActiveKitchen,
  activeKitchen,
  setIsModalOpen,
  setIsShopModalOpen,
}) => {
  const selected = "border-2 border-blue-500";
  return (
    <div className="">
      <div
        className="w-[110px] max-h-[auto] flex flex-col items-center justify-center cursor-pointer"
        onClick={() => {
          if (checkTime()) {
            setActiveKitchen(item.id);
            setIsModalOpen(true);
          } else {
            setIsShopModalOpen(true);
          }
        }}
      >
        <img
          className={`w-[80px] h-[80px] rounded-full ${
            activeKitchen === item.id ? selected : ""
          }`}
          src={item.brand_image}
          alt=""
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/no-image.png";
          }}
        />
        <div className="text-center py-2 text-[12px] font-semibold">
          {item.name}
        </div>
      </div>
    </div>
  );
};

export default MenuItemFilter;
